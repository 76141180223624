import { forwardRef, FunctionComponent, useState } from 'react';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getSpacing } from 'stylesheet';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      maxWidth: getSpacing(150),
      margin: 'auto',
    },
  }),
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ModalProps = {
  titleId: string;
  descriptionId: string;
  redirectionUrl: string;
};

export const Modal: FunctionComponent<ModalProps> = ({
  titleId,
  descriptionId,
  redirectionUrl,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const classes = useStyles();

  const handleAcceptPatientAgreement = () => {
    setIsModalOpen(false);
  };

  return (
    <Dialog
      data-testid="patient-agreement-modal"
      open={isModalOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogTitle>
        <FormattedMessage id={titleId} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id={descriptionId} values={{ br: <br /> }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button href={redirectionUrl} color="default">
          <FormattedMessage id="modal.actions.no" />
        </Button>
        <Button color="primary" onClick={handleAcceptPatientAgreement}>
          <FormattedMessage id="modal.actions.yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
