import styled from 'styled-components';
import { breakpoints, colorPalette, getSpacing } from 'stylesheet';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: ${getSpacing(29)};
  width: 250px;
  margin: ${getSpacing(2)} ${getSpacing(1)};

  @media (min-width: ${breakpoints.tablet}) {
    min-height: ${getSpacing(15)};
    width: 280px;
    margin: ${getSpacing(2)} ${getSpacing(3)};
  }
`;
Container.displayName = 'Container';

export const StyledButton = styled(Button)`
  background-color: white;
  height: 100%;
  color: ${colorPalette.blueDark};
  text-transform: uppercase;
  font-weight: 800;
  display: flex;
  border-radius: ${getSpacing(5)};
  padding: ${getSpacing(3)};
  font-size: 1em;

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: ${getSpacing(8)};
    padding: ${getSpacing(5)};
    font-size: 1.2rem;
  }
`;
StyledButton.displayName = 'StyledButton';

export const ColoredText = styled.span`
  color: ${colorPalette.blueBright};
`;
