import { PageTitle } from 'components/PageTitle/PageTitle';
import styled from 'styled-components';
import { breakpoints, colorPalette, getSpacing, ONE_COLUMN_WIDTH } from 'stylesheet';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const QuestionContainer = styled(PageTitle)`
  margin: ${getSpacing(2)} ${getSpacing(2)} ${getSpacing(5)} ${getSpacing(2)};
  font-weight: 800;
  font-size: 1.8rem;
  color: ${colorPalette.darkGrey};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  max-width: ${ONE_COLUMN_WIDTH};

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`;
CardContainer.displayName = 'CardContainer';
