import { FunctionComponent, ReactNode } from 'react';
import { Typography } from '@material-ui/core';

interface PropsType {
  children: ReactNode;
  className?: string;
  color?: string;
}
export const PageTitle: FunctionComponent<PropsType> = ({ children, className, color }) => (
  <Typography
    style={color !== undefined ? { color } : undefined}
    variant="h2"
    color={color !== undefined ? undefined : 'textPrimary'}
    gutterBottom
    align="center"
    className={className}
  >
    {children}
  </Typography>
);
