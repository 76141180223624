import Link from 'next/link';
import React, { ReactNode } from 'react';
import { Container, StyledButton } from './OptionButton.style';

interface PropsType {
  title: ReactNode;
  route: string;
}

export const OptionButton: React.FunctionComponent<PropsType> = ({ title, route }) => {
  return (
    <Container>
      <Link href={route} passHref legacyBehavior>
        <StyledButton>
          <p>{title}</p>
        </StyledButton>
      </Link>
    </Container>
  );
};
