import { Layout } from 'components/Layout/Layout';

import { OptionButton } from 'components/OptionButton/OptionButton';
import { useIntl } from 'react-intl';
import { NextPage } from 'next';
import { routes } from 'services/routes';
import { ColoredText } from 'components/OptionButton/OptionButton.style';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { clearCondition } from 'redux/Condition';
import { clearProductInfo } from 'redux/ProductInfo';
import { CardContainer, Container, QuestionContainer } from './Home.style';
import { Modal } from '../../Modal/Modal';

const ColorChunks = (chunks: string[]) => <ColoredText>{chunks}</ColoredText>;
ColorChunks.displayName = 'ColorChunks';

export const Home: NextPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCondition());
    dispatch(clearProductInfo());
  }, []);
  return (
    <Layout>
      <Modal
        titleId="home.user_agreement_modal.title"
        descriptionId="home.user_agreement_modal.description"
        redirectionUrl={intl.formatMessage({ id: 'home.user_agreement_modal.redirection_url' })}
      />
      <Container>
        <QuestionContainer>{intl.formatMessage({ id: 'home.description' })}</QuestionContainer>
        <CardContainer>
          <OptionButton
            title={intl.formatMessage(
              { id: 'home.option_buttons.by_condition' },
              { color: ColorChunks },
            )}
            route={routes.BY_CONDITION}
          />
          <OptionButton
            title={intl.formatMessage(
              { id: 'home.option_buttons.by_product' },
              { color: ColorChunks },
            )}
            route={routes.BY_PRODUCT}
          />
        </CardContainer>
      </Container>
    </Layout>
  );
};
